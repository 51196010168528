

/** 
  All of the routes for the Vision UI Dashboard React are added here,
  You can add a new route, customize the routes and delete the routes here.

  Once you add a new route on this file it will be visible automatically on
  the Sidenav.

  For adding a new route you can follow the existing routes in the routes array.
  1. The `type` key with the `collapse` value is used for a route.
  2. The `type` key with the `title` value is used for a title inside the Sidenav. 
  3. The `type` key with the `divider` value is used for a divider between Sidenav items.
  4. The `name` key is used for the name of the route on the Sidenav.
  5. The `key` key is used for the key of the route (It will help you with the key prop inside a loop).
  6. The `icon` key is used for the icon of the route on the Sidenav, you have to add a node.
  7. The `collapse` key is used for making a collapsible item on the Sidenav that has other routes
  inside (nested routes), you need to pass the nested routes inside an array as a value for the `collapse` key.
  8. The `route` key is used to store the route location which is used for the react router.
  9. The `href` key is used to store the external links location.
  10. The `title` key is only for the item with the type of `title` and its used for the title text on the Sidenav.
  10. The `component` key is used to store the component of its route.
*/

// Vision UI Dashboard React layouts
import Dashboard from "layouts/dashboard";
import Light from "layouts/light";
import Billing from "layouts/billing";
import Profile from "layouts/profile";
import Plants from "layouts/plants";
import SignIn from "layouts/authentication/sign-in";
import SignUp from "layouts/authentication/sign-up";
import { FaTent } from "react-icons/fa6";

// Vision UI Dashboard React icons
import { IoAnalytics, IoBulbSharp, IoRocketSharp } from "react-icons/io5";
import { IoIosDocument } from "react-icons/io";
import { BsFillPersonFill } from "react-icons/bs";
import { FaLeaf } from "react-icons/fa";


const routes = [
  {
    type: "collapse",
    name: "Dashboard",
    key: "dashboard",
    route: "/dashboard",
    icon: <IoAnalytics size="15px" color="inherit" />,
    component: Dashboard,
    noCollapse: true,
  },
  {
    type: "collapse",
    name: "Plantas",
    key: "plants",
    route: "/plants",
    icon: <FaLeaf size="15px" color="inherit" />,
    component: Plants,
    noCollapse: true,
  },
  {
    type: "collapse",
    name: "Luz",
    key: "light",
    route: "/light",
    icon: <IoBulbSharp size="15px" color="inherit" />,
    component: Light,
    noCollapse: true,
  },
  {
    type: "collapse",
    name: "Armarios",
    key: "tents",
    route: "/tents",
    icon: <FaTent size="15px" color="inherit" />,
    component: Billing,
    noCollapse: true,
  },
  // { type: "title", title: "", key: "account-pages" },
  {
    type: "collapse",
    name: "Perfil",
    key: "profile",
    route: "/profile",
    icon: <BsFillPersonFill size="15px" color="inherit" />,
    component: Profile,
    noCollapse: true,
  },
  {
    type: "hidden",
    name: "Sign In",
    key: "sign-in",
    route: "/login",
    icon: <IoIosDocument size="15px" color="inherit" />,
    component: SignIn,
    noCollapse: true,
  },
  {
    type: "hidden",
    name: "Sign Up",
    key: "sign-up",
    route: "/register",
    icon: <IoRocketSharp size="15px" color="inherit" />,
    component: SignUp,
    noCollapse: true,
  }
];

export default routes;
